<template>
  <BaseModal
    ref="modal"
    title="Rapportdetails"
    :max-width="maxWidth"
  >
    <PropertyEstimateReportPreviewModalStep1
      v-if="activeStep === 1"
      :estimateReportData="estimateReportData"
      @next="moveForward"
      >
    </PropertyEstimateReportPreviewModalStep1>
    <PropertyEstimateReportPreviewModalStep2
      v-if="activeStep === 2"
      :estimateReportData="estimateReportData"
      @next="moveForward"
      @back="goBack"
    ></PropertyEstimateReportPreviewModalStep2>
    <PropertyEstimateReportPreviewModalStep3
      v-if="activeStep === 3"
      :estimateReportData="estimateReportData"
      @next="moveForward"
      @back="goBack"
      >
    </PropertyEstimateReportPreviewModalStep3>
    <PropertyEstimateReportPreviewModalStep4
      v-if="activeStep === 4"
      ref="step4"
      :estimateReportData="estimateReportData"
      :region="region"
      @back="goBack"
      @generateEstimationReport="generateEstimationReport"
      >
    </PropertyEstimateReportPreviewModalStep4>
    <PropertyEstimateReportPreviewModalStep5
      v-if="activeStep === 5"
      ref="step5"
      :pdfUrl="mergedPdfUrl"
      @back="goBack"
    />
<!--    debug buttons don't remove-->
<!--    <button-->
<!--      class="btn btn-primary tw-mx-1"-->
<!--      title="Deel 1"-->
<!--      @click="generateSection('estimation_report_section_1_nl')"-->
<!--    >-->
<!--      <i class="fas fa-file"></i>-->
<!--    </button>-->
<!--    <button-->
<!--      class="btn btn-warning tw-mx-1"-->
<!--      title="Deel 2"-->
<!--      @click="generateSection('estimation_report_section_2_nl')"-->
<!--    >-->
<!--      <i class="fas fa-file"></i>-->
<!--    </button>-->
<!--    <button-->
<!--      class="btn btn-danger tw-mx-1"-->
<!--      title="Deel 3"-->
<!--      @click="generateSection('estimation_report_section_3_nl')"-->
<!--    >-->
<!--      <i class="fas fa-file"></i>-->
<!--    </button>-->
<!--    <button-->
<!--      class="btn btn-success tw-mx-1"-->
<!--      title="Deel 4"-->
<!--      @click="generateSection('estimation_report_section_4_nl')"-->
<!--    >-->
<!--      <i class="fas fa-file"></i>-->
<!--    </button>-->
<!--    <button-->
<!--      class="btn btn-info tw-mx-1"-->
<!--      title="Deel 5"-->
<!--      @click="generateSection('estimation_report_section_5_nl')"-->
<!--    >-->
<!--      <i class="fas fa-file"></i>-->
<!--    </button>-->
<!--    <button-->
<!--      class="btn btn-default tw-mx-1"-->
<!--      title="Grid"-->
<!--      @click="generateSection('estimation_report_table_nl')"-->
<!--    >-->
<!--      <i class="fas fa-file"></i>-->
<!--    </button>-->
  </BaseModal>
</template>
<script>
import PropertyEstimateReportPreviewModalStep1 from '@/components/properties/PropertyEstimateReportPreviewModalStep1'
import PropertyEstimateReportPreviewModalStep2 from '@/components/properties/PropertyEstimateReportPreviewModalStep2'
import PropertyEstimateReportPreviewModalStep3 from '@/components/properties/PropertyEstimateReportPreviewModalStep3'
import PropertyEstimateReportPreviewModalStep4 from '@/components/properties/PropertyEstimateReportPreviewModalStep4'
import PropertyEstimateReportPreviewModalStep5 from '@/components/properties/PropertyEstimateReportPreviewModalStep5'
import { getEstimationReportData, storeGutenborgFile, updatePropertyEstimate } from '@/services/properties'
import { createDocument, createV2Document, pollGutenborg, pollV2Gutenborg, mergePdf } from '@/services/gutenborgService'
import { poll } from '@/utils/helpers'
import { successModal, errorModal } from '@/modalMessages'

export default {
  name: 'PropertyEstimateReportPreviewModal',
  components: {
    PropertyEstimateReportPreviewModalStep1,
    PropertyEstimateReportPreviewModalStep2,
    PropertyEstimateReportPreviewModalStep3,
    PropertyEstimateReportPreviewModalStep4,
    PropertyEstimateReportPreviewModalStep5
  },
  props: {
    propertyId: {
      type: [Number, String],
      required: true
    },
    region: {
      type: Number,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  constants: {
    LAST_STEP: 5,
    REPORT_SECTION_1_TEMPLATE_KEY: 'estimation_report_section_1_nl',
    REPORT_SECTION_2_TEMPLATE_KEY: 'estimation_report_section_2_nl',
    REPORT_SECTION_3_TEMPLATE_KEY: 'estimation_report_section_3_nl',
    REPORT_SECTION_4_TEMPLATE_KEY: 'estimation_report_section_4_nl',
    REPORT_TABLE_TEMPLATE_KEY: 'estimation_report_table_nl',
    REPORT_SECTION_5_TEMPLATE_KEY: 'estimation_report_section_5_nl'
  },
  data () {
    return {
      activeStep: 1,
      estimateId: null,
      estimateReportData: {},
      mergedPdfUrl: null
    }
  },
  computed: {
    maxWidth () {
      return this.activeStep === this.LAST_STEP ? 'tw-max-w-7xl' : 'tw-max-w-xl'
    }
  },
  methods: {
    async show (estimate) {
      this.activeStep = 1
      this.estimateId = estimate.id
      const response = await getEstimationReportData(this.propertyId, estimate.id, this.language)
      this.estimateReportData = response.data
      this.$set(estimate, 'loading_report', false) // Set the value in the estimate of the parent component
      this.$refs.modal.show()
    },
    goBack () {
      this.activeStep -= 1
    },
    moveForward () {
      if (this.activeStep < this.LAST_STEP) {
        this.activeStep += 1
      }
    },
    async generateSection (section) {
      // For debugging purposes only
      let pollResult
      const mergeData = { ...this.estimateReportData, show_factor: true }
      if (section === this.REPORT_TABLE_TEMPLATE_KEY) {
        const documentPayload = {
          merge_data: btoa(encodeURIComponent(JSON.stringify(mergeData))),
          template_key: section,
          config: {
            type: 'pdf',
            destination: 'temp',
            format: 'B64PDF',
            landscape: false
          }
        }
        const document = await createDocument(documentPayload)
        pollResult = await poll(document?.data?.uuid, pollGutenborg, 1000)
      } else {
        const documentPayload = {
          preview: true,
          data: { ...this.estimateReportData },
          lang1: 'nl',
          template_key: section
        }
        const document = await createV2Document(documentPayload)
        pollResult = await poll(document?.data?.job_id, pollV2Gutenborg, 1000)
      }
      console.log(pollResult?.url)
      return pollResult?.url
    },
    async generateEstimationReport (configuration) {
      try {
        const templateKeys = [
          this.REPORT_SECTION_1_TEMPLATE_KEY,
          this.REPORT_SECTION_2_TEMPLATE_KEY,
          this.REPORT_SECTION_3_TEMPLATE_KEY,
          this.REPORT_SECTION_4_TEMPLATE_KEY,
          this.REPORT_TABLE_TEMPLATE_KEY,
          this.REPORT_SECTION_5_TEMPLATE_KEY
        ]

        const mergeData = { ...this.estimateReportData, ...configuration }

        const generatePromises = templateKeys.map((templateKey) =>
          (async () => {
            let pollResult
            if (templateKey === this.REPORT_TABLE_TEMPLATE_KEY) {
              const documentPayload = {
                merge_data: btoa(encodeURIComponent(JSON.stringify(mergeData))),
                template_key: templateKey,
                config: {
                  type: 'pdf',
                  destination: 'temp',
                  format: 'B64PDF',
                  landscape: false
                }
              }
              const document = await createDocument(documentPayload)
              pollResult = await poll(document?.data?.uuid, pollGutenborg, 1000)
            } else {
              const documentPayload = {
                preview: process.env.VUE_APP_ENVIRONMENT !== 'prod',
                data: { ...mergeData },
                lang1: 'nl',
                template_key: templateKey
              }

              const document = await createV2Document(documentPayload)
              pollResult = await poll(document?.data?.job_id, pollV2Gutenborg, 1000)
            }

            // Stop execution if pollResult indicates failure (status = 2)
            if (pollResult?.status === 2) {
              throw new Error(`Document generation failed for template ${templateKey}`)
            }

            return { [templateKey]: pollResult?.url }
          })()
        )

        const resultsArray = await Promise.all(generatePromises) // Stops immediately if any promise rejects
        const generatedSections = Object.assign({}, ...resultsArray)

        const payload = {
          pdf_urls: [
            generatedSections[this.REPORT_SECTION_1_TEMPLATE_KEY],
            generatedSections[this.REPORT_SECTION_2_TEMPLATE_KEY],
            generatedSections[this.REPORT_SECTION_3_TEMPLATE_KEY],
            generatedSections[this.REPORT_SECTION_4_TEMPLATE_KEY],
            generatedSections[this.REPORT_TABLE_TEMPLATE_KEY],
            generatedSections[this.REPORT_SECTION_5_TEMPLATE_KEY]
          ]
        }

        const response = await mergePdf(payload)
        this.mergedPdfUrl = response.data.merged_pdf_url
        await this.saveEstimationReport()
        this.$refs.step4.stopGenerating()
        this.moveForward()
        return response.merged_pdf_url
      } catch (error) {
        console.error('Error generating estimation report:', error.message)
        this.$refs.step4.stopGenerating() // Ensure button is re-enabled
        errorModal('Er is een fout opgetreden bij het genereren van het schattingsrapport')
      }
    },
    async saveEstimationReport () {
      const payload = {
        url: this.mergedPdfUrl,
        type: 'estimation_report',
        meta: { estimate_id: this.estimateId }
      }

      const storeFileResponse = await storeGutenborgFile(this.propertyId, payload)
      const fileId = storeFileResponse.data.id
      const updateEstimateResponse = await updatePropertyEstimate(this.propertyId, this.estimateId, { property_file: fileId })
      successModal('Het schattingsrapport is succesvol opgeslagen')
      this.$emit('saved')
      return updateEstimateResponse
    }
  }
}
</script>
